import React, { createContext, useState, useEffect } from 'react';
import { getMainPageData } from '../widgets/CarShowcase/lib/request'; // Подключаем API для получения данных

export const MainPageContext = createContext();

export function MainPageProvider({ children }) {
    const [mainPageData, setMainPageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const cachedData = localStorage.getItem('mainPageData'); // Проверяем, есть ли кэшированные данные
        if (cachedData) {
            setMainPageData(JSON.parse(cachedData)); // Используем кэшированные данные
            setLoading(false);
        } else {
            // Если данных нет, загружаем их с сервера
            async function fetchData() {
                try {
                    const data = await getMainPageData();
                    setMainPageData(data);
                    localStorage.setItem('mainPageData', JSON.stringify(data)); // Кэшируем данные
                } catch (error) {
                    setError('Ошибка при загрузке данных');
                } finally {
                    setLoading(false);
                }
            }
            fetchData();
        }
    }, []);

    return (
        <MainPageContext.Provider value={{ mainPageData, loading, error }}>
            {children}
        </MainPageContext.Provider>
    );
}
