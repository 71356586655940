import { CarFiltersForMarket } from "../../../features/CarFiltersForMarket";
import { Sedan } from "../../../shared/ui/Sedan/ui/Sedan";
import styles from './BigCarCard.module.css'

export function BigCarCard() {
    return (
        <div className={styles.outer}>        
        <div className={styles.layout}>
            <div className={styles.titleContainer}>
                <Sedan color={'#444444'} /> 
                <p className={styles.carTitle}>BMW 7 серии 730d xDrive, рестайлинг (2014 - 2019), 2017, кузов: хэтчбек 5 дв., робот</p>
            </div>
            <CarFiltersForMarket/>
            <div className={styles.content}>
                <div className={styles.infoContainer}>
                    <p className={styles.title}>Количество</p>
                    <p className={styles.text}>53 шт.</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Снято за 3 мес.</p>
                    <p className={styles.text}>31 шт.</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Средняя цена</p>
                    <p className={styles.text}>41 100€</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ср. срок продажи</p>
                    <p className={styles.text}>29 дней</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ликвидность</p>
                    <p className={styles.text}>79%</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ср. пробег</p>
                    <p className={styles.text}>65 000 км.</p>
                </div>
            </div>
            </div>
        </div>
    )
}