import { ChangeAccountInfoForm } from "../../../features/ChangeAccointInfoForm/ui/ChangeAccountInfoForm";
import { ChangeUserLoginInfoForm } from "../../../features/ChangeUserLoginInfoForm/ui/ChangeUserLoginInfoForm";
import { Navbar } from "../../../widgets/navbar";
import styles from './UserAccountPage.module.css'

export function UserAccountPage() {
    return (
        <div className={styles.layout}>
            
            <div className={styles.container}>
            <p className={styles.text}>Аккаунт</p>
                <div className={styles.content}>
                    {/* <ChangeAccountInfoForm/> */}
                    <ChangeUserLoginInfoForm/>
                </div>
            </div>
        </div>
    )
}