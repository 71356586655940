import { useState } from 'react'
import { MultiSelect } from '../../../shared/ui/MultiSelect'
import { Sedan } from '../../../shared/ui/Sedan/ui/Sedan'
import { Selector } from '../../../shared/ui/Selector'
import styles from './CarFiltersForMarket.module.css'

export function CarFiltersForMarket() {

    const [regions, setRegions] = useState()
    const [cities, setCities] = useState()
    const [radius, setRadius] = useState()
    const [offers, setOffers] = useState()

    const regionsOptions = ['Германия', 'Италия', 'Чехия']
    const citiesOptions = ['Берлин', 'Гамбург']
    const radiusOptions = ['10', '20', '30', '40']
    const offersOptions = ['Актуальные и снятые за 2 мес.']
    
    return (
        <div className={styles.layout}>
            <div className={styles.filtersContainer}>
                <MultiSelect label={'Регионы'} selected={['Германия']} onSelected={setRegions} values={regionsOptions}/>
                <MultiSelect label={'Города / ZIP'} selected={['Габсбург']} onSelected={setCities} values={citiesOptions}/>
                <Selector label={'Радиус'} values={radiusOptions}/>
                <Selector label={'Предложения'} values={offersOptions} />
            </div>
        </div>
    )
}