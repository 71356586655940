import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import europeData from "./europe.geojson";
import { useState } from "react";


const highlightedCountries = ["ITA", "DEU"]; // ISO Alpha-3 коды стран (Италия и Германия)

const MapChart = ({onChangeCountries}) => {
    const [selectedCountries, setSelectedCountries] = useState([]); // Для хранения кодов выбранных стран
    const [hoveredCountry, setHoveredCountry] = useState(null); // Для хранения названия страны при наведении
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // Для хранения позиции мыши

    console.log(hoveredCountry)
    console.log(mousePosition)

    const handleMouseEnter = (name, event) => {
      setHoveredCountry(name); // Устанавливаем название страны при наведении
      setMousePosition({ x: event.clientX, y: event.clientY }); // Сохраняем позицию мыши
  };

    const handleMouseLeave = () => {
        setHoveredCountry(null); // Очищаем название страны, когда курсор покидает её
    };
  
    const handleCountryClick = (isoCode) => {
      setSelectedCountries((prevSelected) => {
        // Проверяем, есть ли страна уже в массиве выбранных
        if (prevSelected.includes(isoCode)) {
          // Если да, убираем её
          return prevSelected.filter((code) => code !== isoCode);
        } else {
          return [...prevSelected, isoCode];

        }
      });

      console.log(isoCode)
    };
  

    
    return (
      <>
      <ComposableMap
        projection="geoAzimuthalEqualArea" // Установка проекции для Европы
        projectionConfig={{
          rotate: [-10, -52, 0],
          scale: 900
        }}
        style={{ width: "50%", height: "auto"}} // Подгоняем размеры карты под контейнер
      >
        <Geographies geography={europeData}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const isSelected = selectedCountries.includes(geo.rsmKey);
                console.log(geo)
                const countryName = geo.properties.name; // Проверяем, выбрана ли страна
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo} // Цвет страны: оранжевый если выбрана, серый по умолчанию
                    stroke="#FFFFFF"
                    style={{
                      default: {
                        outline: "none", // Убираем обводку при фокусе
                        border: "none",
                        cursor: "pointer", // Указатель при наведении
                        strokeWidth: 0.5,
                        fill: isSelected ? "rgb(255,110,4)" : "#D6D6DA"
                      },
                      hover: {
                        fill: "rgb(131,131,131)", // Серый цвет при наведении
                        outline: "none",
                        border: "none",
                      },
                      pressed: {
                        fill: isSelected ? "rgb(255,110,4)" : "#D6D6DA", // Оранжевый цвет при нажатии на выбранные страны
                        outline: "none",
                        
                      }
                    }}
                    onClick={() => {
                      handleCountryClick(geo.rsmKey);
                       // Обработка клика по стране
                    }}
                    onMouseEnter={(event) => handleMouseEnter(countryName, event)} // Обработка наведения на страну
                                        onMouseLeave={handleMouseLeave} // Обработка ухода курсора со страны
                  />
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
      {hoveredCountry && (
        <div
            style={{
                position: "absolute",
                top: mousePosition.y, // Смещение по Y от курсора
                left: mousePosition.x, // Смещение по X от курсора
                backgroundColor: "black",
                padding: "5px",
                border: "1px solid black",
                pointerEvents: "none", // Избегаем вмешательства в события мыши
                zIndex: 10,
                opacity: 1,
                color: 'rgba(255, 255, 255, 1)',
                fontSize: '12px',
                fontWeight: 400,
                borderRadius: '4px'
            }}
        >
            {hoveredCountry}
        </div>
    )}
    </>
    );
  };
export default MapChart;
