import React from 'react';
import { CarFilters } from '../../../features/СarFilters'
import { CarsList } from '../../../widgets/CarsList/ui/CarsList';
import { Navbar } from '../../../widgets/navbar';
import styles from './OffersPage.module.css'
import { ComposableMap, Geographies, Geography } from "react-simple-maps";


export function OffersPage() {

    

    return (
        <React.Fragment>
        <div className={styles.container}>
            <div className={styles.content}>
                <CarFilters/>
                <CarsList/>
            </div>
            
        </div>
        </React.Fragment>
    )
}