import React, { useState, useRef, useEffect } from 'react';
import styles from './Selector.module.css';
import { ArrowDown } from '../../Icons/ArrowDown';

export const Selector = ({ label, values, type, value, onValue, width }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
    setIsFocused(true); // При открытии дропдауна считаем, что он в фокусе
  };

  const handleValueClick = (value) => {
    setSelectedValue(value);
    setIsDropdownOpen(false); // Закрываем дропдаун после выбора
    setIsFocused(false); // Снимаем фокус после выбора
    onValue(value)
  };

  // Закрытие дропдауна при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef} style={{width: width}}>
      <div
        className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ''}`}
        onClick={toggleDropdown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={ type=='left' ? {borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0} : type=='right' ? {borderTopLeftRadius: 0, borderBottomLeftRadius: 0} : {}}
      >
        <span
          className={`${styles.floatingLabel} ${selectedValue || isFocused ? styles.floatingLabelActive : ''}`}
        >
          {label}
        </span>
        {selectedValue && (
          <span className={styles.selectedText}>{selectedValue}</span>
        )}
        <span className={styles.arrow}>
          <ArrowDown isActive={isDropdownOpen} />
        </span>
      </div>
      {isDropdownOpen && (
        <div className={styles.values}>
          {values.map((value, index) => (
            <div
              key={index}
              className={`${styles.value} ${selectedValue === value ? styles.selected : ''}`}
              onClick={() => handleValueClick(value)}
            >
              {value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

