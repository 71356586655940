import React, { useState, useRef, useEffect } from 'react';
import styles from './MultiSelect.module.css';
import { ArrowDown } from '../../Icons/ArrowDown';

export const MultiSelect = ({ label, values, selected, onSelected }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(selected);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };


  const handleOptionClick = (option) => {
    const isSelected = selectedValues.includes(option);
    const updatedValues = isSelected
      ? selectedValues.filter((item) => item !== option)
      : [...selectedValues, option];
    setSelectedValues(updatedValues);
    onSelected(updatedValues)
  };

  const handleRemoveOption = (option) => {
    setSelectedValues((prev) => prev.filter((item) => item !== option));
  };

  // Закрытие дропдауна при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ''}`} onClick={toggleDropdown}>
        <label
          className={`${styles.label} ${
            selectedValues.length > 0 || isDropdownOpen ? styles.floating : ''
          }`}
        >
          {label}
        </label>
        {selectedValues.length > 0 ? (
          <span className={styles.selectedText}>
            {selectedValues.join(', ')}
          </span>
        ) : (
          <span className={styles.placeholder}>{label}</span>
        )}
        <span className={styles.arrow}><ArrowDown isActive={isDropdownOpen} /></span>
      </div>
      {isDropdownOpen && (
        <div className={styles.values}>
          {values.map((option, index) => (
            <div
              key={index}
              className={`${styles.option} ${selectedValues.includes(option) ? styles.selected : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
          <div className={styles.buttons}>
            <button className={styles.deleteButton}>Сбросить</button>
            <button className={styles.okButton}>Применить</button>
          </div>
        </div>
        
      )}
    </div>
  );
};
