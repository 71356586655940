import { Accordeon } from '../../../shared/ui/Accordeon'
import styles from './RegionsAccordeon.module.css'

export function RegionsAccordeon() {
    return (
        <div className={styles.container}>
            <Accordeon/>
            
        </div>
    )
}