import { PATH_API } from "../../config/config";
const BaseapiUrl = PATH_API;

export async function sendPostRequest(path, data) {
    let response = await fetch(`${BaseapiUrl}/v1/${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data)
      });
    checkAuth(response)
    let result = await response.json();
    return result
}

export async function sendGetRequest(path, data) {
  let response = await fetch(`${BaseapiUrl}/v1/${path}` + '?' + ( new URLSearchParams( data ) ).toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    });
  checkAuth(response)
  let result = await response.json();
  return result
}

export async function sendPutRequest(path, data) {
  let response = await fetch(`${BaseapiUrl}/v1/${path}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    });
  checkAuth(response)
  let result = await response.json();
  return result
}

export function checkAuth(res){
  if(res.status === 403 || res.status === 401){
    window.location.replace('https://caraii.com/login');
  }
}
