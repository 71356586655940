import { useState, useCallback, useContext, createContext } from "react";

export const useCreateFavoriteContext = function(props) {
    const [favoriteList, _setFavoriteList] = useState([])
    const setFavoriteList = useCallback((newValues) => {
        _setFavoriteList(newValues)
      }, []);
    return {
        favoriteList,
        setFavoriteList,
    };
  }

const Context = createContext(null);

export const FavoriteContextProvider = ({ children, ...props }) => {
    const context = useCreateFavoriteContext(props);
    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useFavoriteContext() {
    const context = useContext(Context);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}