import { useState } from "react";
import { Button } from "../../../shared/ui/button";
import { Input } from "../../../shared/ui/input";
import { sendPhoneData } from "../lib/request";

import styles from './LoginPhoneForm.module.css'


export function LoginPhoneForm({onAccepted, onSignKey}) {

    const [phone, setPhone] = useState()

    async function getSignKey() {
        const res = await sendPhoneData(phone)
        
        if (res.data != undefined) {
            console.log(res.data["sign_key"])
            onSignKey(res.data["sign_key"])
            onAccepted('phoneCode')
        }
        onAccepted('phoneCode')

    }

    return (
        <div className={styles.container}>
            <Input onChange={(event) => setPhone(event.target.value)} placeholder={"Телефон"}/>
            <Button fontSize="30px" onClick={() => getSignKey()} width="unset">{'>'}</Button>
        </div>
    )   
}