import { sendGetRequest } from "../../../shared/api/lib/requests";


export async function getMainPageData() {
    const res = await sendGetRequest('cars-system/main-page-info', {
        'access_token': localStorage.getItem('accessToken'),
        'cars_count': 25
    });
    return res;
}
