import { Link, Outlet, useLocation } from 'react-router-dom'
import styles from './Navbar.module.css'
import {ReactComponent as UserIcon} from '../../../shared/assets/profile.svg'
import React, { useEffect, useState } from 'react';
import { getFavouriteData } from '../lib/request';
import { useFavoriteContext } from '../../../contexts/FavoriteContext';
const Image = React.memo(function Image({ src, width }) {
    return (
        <Link className={styles.menu_logo}>
            <img src={src} width={width}/>
        </Link>
    )
  });
export function Navbar() {
    const location = useLocation();
    const [url, setUrl] = useState(null);
    const {setFavoriteList, favoriteList} = useFavoriteContext()
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);
    useEffect(() => {
        async function getData(){
            setFavoriteList((await getFavouriteData()).data.cars)
        }
        getData()
    }, [])
    
    return (
        <div>
        <div className={styles.container}>
            <div className={styles.menu}>
                
                <Image width='90px' src='caraii.jpg'/>
                {/* <div> */}
                    <Link className={styles.menu_point + (url === '/' ? " " + styles.active : '')} to={'/'}>
                        Главная
                    </Link>
                    <Link className={styles.menu_point + (url === '/search' ? " " + styles.active : '')} to={'/search'}>
                        Поиск
                    </Link>
                    <Link className={styles.menu_point + (url === '/favourites' ? " " + styles.active : '')} to={'/favourites'}>
                        Избранное
                        <span className={styles.favouriteCount}>
                            {favoriteList.length}
                        </span>
                    </Link>
                    <Link className={styles.menu_point + (url === '/market' ? " " + styles.active : '')} to={'/market'}>
                        Рынок
                    </Link>
                    <Link className={styles.menu_point + (url === '/chat' ? " " + styles.active : '')} to={'/chat'}>
                        AI
                    </Link>
                    <Link className={styles.menu_point + (url === '/api' ? " " + styles.active : '') + " " + styles.menu_point_market} to={'/api'}>
                        API
                    </Link>
                    <Link className={styles.menu_point + (url === '/new-ad' ? " " + styles.active : '')} to={'/new-ad'}>
                        Создать объявление
                    </Link>
                {/* </div> */}
            </div>
            <div className={styles.user}>
                <UserIcon/>
                <p className={styles.user_name}>
                Виталий
                </p>
            </div>
        </div>
        <Outlet/>
        </div>
    )
}