import React, { useContext } from 'react';
import { MainPageContext } from '../../../../contexts/MainPageContext'; // Исправляем путь
import styles from './ListOfBrands.module.css';

export function ListOfBrands() {
    const { mainPageData, loading, error } = useContext(MainPageContext);

    if (loading) {
        return <div>Загрузка брендов...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Преобразуем top_brands в массив пар [ключ, значение]
    const brands = Object.entries(mainPageData?.data?.top_brands || {});

    if (!brands.length) {
        return <div>Нет данных для отображения</div>;
    }

    return (
        <div className={styles.carList}>
            {brands.map(([brand, count], index) => (
                <Brand brand={brand} count={count} key={index} />
            ))}
        </div>
    );
}

function Brand({ brand, count }) {
    return (
        <div className={styles.carItem}>
            <span className={styles.carBrand}>{brand}</span>
            <span className={styles.carCount}>{count}</span>
        </div>
    );
}
