import { Input } from "../../../shared/ui/input"
import { Button } from "../../../shared/ui/button"

import { sendEmailCodeData } from "../lib/request"
import { sendPhoneCodeData } from "../lib/request"

import styles from './AcceptCodeForm.module.css'
import { useState } from "react"

export function AcceptCodeForm({type, onAccepted, signKey}) {

    const [emailCode, setEmailCode] = useState()
    const [phoneCode, setPhoneCode] = useState()

    async function verifyEmailCode() {
        const res = await sendEmailCodeData(signKey, emailCode)
        

            
        if (res.data != undefined) {
            onAccepted('end')
        } 
        onAccepted('end')
    }

    async function verifyPhoneCode() {
        console.log(signKey + phoneCode)
        const res = await sendPhoneCodeData(signKey, phoneCode)

        if (res.data != undefined) {
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem("refreshToken", res.data.refresh_token);
            onAccepted('end')
        } 
        
    }

    return (
        <div className={styles.container}>
            <Input onChange={(event) => setPhoneCode(event.target.value)} placeholder={"Код"}/>
            <Button fontSize="30px" width="unset" onClick={() => verifyPhoneCode()}>{'>'}</Button>
        </div>
    )
}