import { Button } from "../../../shared/ui/button";
import { Input } from "../../../shared/ui/input";
import styles from './LoginEmailForm.module.css'
import { sendEmailData } from "../lib/request";
import { useState } from "react";


export function LoginEmailForm({onAccepted, signKey}) {

    const [email, setEmail] = useState()

    async function sendEmail() {
        onAccepted('emailCode')
        const res = await sendEmailData(signKey, email)
    }

    return (
        <div> 
    
                <div className={styles.container}>
                    <Input onChange={(event) => setEmail(event.target.value)} placeholder={"Email"}/>
                    <Button width="30%" onClick={() => sendEmail()}>Send code</Button>
                </div>
            

        </div>
    )   
}