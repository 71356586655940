import MapChart from "../../../shared/ui/Map/map"
import styles from './MapStatistics.module.css'
import { MarketRegionsHeader } from "../../../shared/ui/MarketRegionsHeader"
import { RegionsAccordeon } from "../../RegionsAccordeon"

export function MapStatistics() {
    return (
        <div className={styles.container}>
            <MapChart/>
            <div className={styles.content}>   
                <MarketRegionsHeader/>
                <RegionsAccordeon/>
            </div>
        </div>
    )
}