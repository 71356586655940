import { sendPostRequest } from "../../../shared/api";
import { sendPutRequest } from "../../../shared/api/lib/requests";
export async function sendPhoneCodeData(signKey, verifyCode) {
    const res = await sendPostRequest('auth/confirm-login', {'sign_key': signKey, "verify_code": verifyCode})
    return res
}

export async function sendEmailCodeData(signKey, verifyCode) {
    const res = await sendPostRequest('confirm-email', {'sign_key': signKey, "verify_code": verifyCode})
    return res
}