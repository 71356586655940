import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import styles from './SmallCarCard.module.css';
import defaultCarImage from '../../../shared/assets/default_car_icon.png';

export function SmallCarCard({ car }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate(); // Инициализируем хук useNavigate для редиректа

    let parsedImageUrls = [];

    try {
        parsedImageUrls = typeof car.image_urls === 'string' ? JSON.parse(car.image_urls) : [];
    } catch (error) {
        console.error('Ошибка при парсинге image_urls:', error);
        parsedImageUrls = [];
    }

    const carImage = parsedImageUrls.length > 0 && parsedImageUrls[0].url ? parsedImageUrls[0].url : defaultCarImage;

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    const handleCardClick = () => {
        navigate(`/cars/${car.id}`, { state: { car } }); // Редирект на страницу с конкретной машиной
    };

    return (
        <div className={styles.carCard} onClick={handleCardClick} style={{ cursor: 'pointer' }}> {/* Добавляем обработчик клика */}
            <div className={`${styles.carImageWrapper} ${isLoaded ? styles.loaded : ''}`}>
                <img 
                    src={carImage} 
                    alt={car.model} 
                    className={styles.carImage} 
                    onLoad={handleImageLoad} 
                />
            </div>
            <div className={styles.carInfo}>
                <div className={styles.carPrice}>{car.price_eur} €</div>
                <div className={styles.carModel}>{car.mark} {car.model}</div>
                <div className={styles.carDetails}>{car.year} / {car.km_age === 0 ? 'Новый' : `${car.km_age} км`}</div>
            </div>
        </div>
    );
}
