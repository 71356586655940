import styles from './ListOfTypes.module.css'


export function ListOfTypes({values, selected, onSelect}) {
    return (
        <div className={styles.container}>
            {values.map((value) => <span onClick={() => onSelect(value)} className={selected == value ? styles.selected : styles.default} >{value}</span>)}
        </div>
    )
}
