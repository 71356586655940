import { useLocation } from 'react-router-dom';
import styles from './CarDetails.module.css'; // Стили для кастомной сцены

export function CarDetails() {
    const location = useLocation();
    const car = location.state?.car; // Получаем объект car из переданного состояния

    if (!car) {
        return <div>Машина не найдена</div>;
    }

    return (
        <div className={styles.carDetailsContainer}>
            <h1>{car.mark} {car.model}</h1>
            <img src={car.image_urls ? car.image_urls[0] : 'defaultImage.jpg'} alt={car.model} className={styles.carImage} />
            <div className={styles.carInfo}>
                <p>Цена: {car.price_eur} €</p>
                <p>Год: {car.year}</p>
                <p>Пробег: {car.km_age === 0 ? 'Новый' : `${car.km_age} км`}</p>
            </div>
        </div>
    );
}
