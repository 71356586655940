import { StyleSheetContext } from 'styled-components'
import styles from './Accordeon.module.css'
import { useScrollTrigger } from '@mui/material'
import { useState } from 'react'

export function Accordeon() {

    const [isAccordionOpen, setIsAccordionOpen] = useState(false)

    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                <div className={styles.column} style={{display: 'flex', gap: 10}}>
                    <span className={styles.plusButton} onClick={() => setIsAccordionOpen(!isAccordionOpen)} >+</span>
                    <p className={styles.title}>Баден-Вюртемберг</p>
                </div>

                <div className={styles.column}>
                    <p className={styles.text}>42 шт.</p>
                </div>

                <div className={styles.column}>
                    <p className={styles.text}>87</p>
                </div>

                <div className={styles.column}>
                    <p className={styles.text}>29 дней</p>
                </div >

                <div className={styles.column}>
                    <p className={styles.textBold}>41 100€</p>
                </div>

                <div className={styles.column}>
                    <p className={styles.textGreen}>4.33</p>
                </div>
            </div>
            {
                isAccordionOpen === true && <div className={styles.accordionElement}>
                                <div className={styles.column}>
                                <p className={[styles.textBoldMargin]}>Баден-Вюртемберг</p>
                            </div>

                            <div className={styles.column}>
                                <p className={styles.text}>42 шт.</p>
                            </div>

                            <div className={styles.column}>
                                <p className={styles.text}>87</p>
                            </div>

                            <div className={styles.column}>
                                <p className={styles.text}>29 дней</p>
                            </div >

                            <div className={styles.column}>
                                <p className={styles.textBold}>41 100€</p>
                            </div>

                            <div className={styles.column}>
                                <p className={styles.textGreen}>4.33</p>
                            </div>
                </div>
            }
        </div>
    )
}