import { BigCarCard } from "../../../widgets/BigCarCard/ui/BigCarCard";
import { MapStatistics } from "../../../widgets/MapStatistics";
import { Navbar } from "../../../widgets/navbar";
import styles from './MarketPage.module.css'
import React from "react";

export function MarketPage() {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <BigCarCard/>
                <MapStatistics/>
                
            </div>
        </React.Fragment>
        

    )
}