import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import styles from './CarRow.module.css';
import defaultCarImage from '../../../shared/assets/default_car_icon.png';
import { Sedan } from '../../../shared/ui/Sedan/ui/Sedan';

export function CarRow({ car }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate(); // Инициализируем хук useNavigate для редиректа

    let parsedImageUrls = [];

    try {
        parsedImageUrls = typeof car.image_urls === 'string' ? JSON.parse(car.image_urls) : [];
    } catch (error) {
        console.error('Ошибка при парсинге image_urls:', error);
        parsedImageUrls = [];
    }

    const carImage = parsedImageUrls.length > 0 && parsedImageUrls[0].url ? parsedImageUrls[0].url : defaultCarImage;

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    const handleCardClick = () => {
        navigate(`/cars/${car.id}`, { state: { car } }); // Редирект на страницу с конкретной машиной
    };

    return (
        <div>
            <div className={styles.image}>
                    <img height={'80px'} width={'110px'} className={styles.image} src={carImage} alt='car'/>
                </div>

                <div>
                    <p className={styles.boldText}>12:45</p>
                    <p className={styles.smallText}>12 авг.</p>
                </div>

                <div>
                    <p className={styles.carNameText}>{car.mark} {car.model}</p>
                </div>

                <div>
                    <p className={styles.boldText}>{car.year}</p>
                </div>

                <div>
                    <p className={styles.boldText}>car.price_eur $</p>
                </div>

                <div>
                    <p className={styles.boldText}>{car.km_age === 0 ? 'Новый' : `${car.km_age} км`}</p>
                </div>

                <div>
                    <p className={styles.boldText}>Вариатор</p>
                    <p className={styles.smallText}>2.4 л.</p>
                </div>

                <div>
                    <p className={styles.boldText}>Берлин</p>
                </div>

                <div>
                    <Sedan color={'#35373A'} />
                </div>
            </div>
    );
}
