import styles from './MarketRegionsHeader.module.css'

export function MarketRegionsHeader() {
    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <p>Регион</p>
            </div>

            <div className={styles.column}>
                <p>Кол-во</p>
            </div>

            <div className={styles.column}>
                <p>Снято за 3 мес.</p>
            </div>

            <div className={styles.column}>
                <p>Ср. срок продажи</p>
            </div >

            <div className={styles.column}>
                <p>Средняя цена</p>
            </div>

            <div className={styles.column}>
                <p>Рейтинг</p>
            </div>


        
        </div>
    )
}