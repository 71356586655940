import { useEffect, useState } from "react"
import { LoginEmailForm } from "../../../features/LoginEmailForm"
import { LoginPhoneForm } from "../../../features/LoginPhoneForm"
import { AcceptCodeForm } from "../../../features/AcceptCodeForm"
import styles from './RegistrationSteps.module.css'
import { Button } from "../../../shared/ui/button"
import { useNavigate } from "react-router-dom"

function LoginHeader({stage}){
    const [text, setText] = useState('')

    useEffect(() => {
        if(stage === 'phone')
            setText('Вход в CarAii')
        else if(stage ==='phoneCode')
            setText('Введите код')
        else if(stage == 'end')
            setText("Успешный вход")
    }, [stage])

    return (
        <p className={styles.headerText}>{text}</p>
    )
}

export function RegistrationSteps() {

    const [stage, setStage] = useState('phone')
    const [signKey, setSignKey] = useState('')
    const navigation = useNavigate()

    return (
        <>
        {
            stage !== 'end' ?
            <div className={styles.container}>
                <LoginHeader stage={stage}/>
            <div className={styles.content}>
                {
                    stage === 'phone' ?
                    <LoginPhoneForm onAccepted={setStage} onSignKey={setSignKey}/> :
                    stage === 'phoneCode' ? 
                    <AcceptCodeForm onAccepted={setStage} type={'phone'} signKey={signKey}/> :
                    stage === 'email' ?
                    <LoginEmailForm onAccepted={setStage}/> :
                    stage === 'emailCode' ?
                    <AcceptCodeForm onAccepted={setStage} type={'email'}/>
                    : null
                }
            </div>
        </div>
        : 
        <div className={styles.container}>
            <div className={styles.endRegistration}>
                <LoginHeader stage={stage}/>
                <Button onClick={() => navigation('/')} width="50%">Перейти на сайт</Button>
            </div>
        </div>
        }
       
        </>
    )
}