import { useFavoriteContext } from "../../../contexts/FavoriteContext"
import { CarsList } from "../../../widgets/CarsList/ui/CarsList"
import { Navbar } from "../../../widgets/navbar"
import styles from './FavouritesPage.module.css'

export function FavouritesPage() {
    const {favoriteList} = useFavoriteContext()
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Избранное</h1>
            <CarsList cars={favoriteList}/>
        </div>
    )
}