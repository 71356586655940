import { CarsList } from "../../../widgets/CarsList/ui/CarsList";
import { Chat } from "../../../widgets/Chat";
import { Navbar } from "../../../widgets/navbar";
import styles from './ChatPage.module.css'

export function ChatPage() {
    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                <h1>Чат</h1>
                <div className={styles.content}>
                    <Chat/>

                </div>
                
            </div>
            <CarsList/>
        </div>
    )
}