import { CarsList } from "../../../widgets/CarsList/ui/CarsList";
import { Chat } from "../../../widgets/Chat";
import { Navbar } from "../../../widgets/navbar";
import styles from './ApiPage.module.css'

export function ApiPage() {
    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header_info}>
                        <h1>API</h1>
                    </div>
                    <div className={styles.header_info}>API carpass временно не доступна. Мы зафиксировали твое желание воспользоваться api и вернемся с ответом совсем скоро!</div>
                </div>  
            </div>
        </div>
    )
}