import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate для редиректа
import styles from './SearchButton.module.css'; // Отдельные стили для компоненты

export function SearchButton({ icon, label, redirectPath }) {
    const [isHovered, setIsHovered] = useState(false); // Управляем состояние hover
    const navigate = useNavigate(); // Инициализируем навигацию

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    // Обработчик клика для редиректа
    const handleClick = () => {
        navigate(redirectPath); // Редирект на переданный путь
    };

    return (
        <button
            className={styles.selectButton}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick} // Добавляем редирект по клику
        >
            <img
                src={icon}
                alt="icon"
                className={styles.icon}
                style={{
                    filter: isHovered ? 'invert(1)' : 'invert(0)', // Меняем цвет на белый при hover
                }}
            />
            {label}
        </button>
    );
}
