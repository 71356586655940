import React, { useContext } from 'react';
import { MainPageContext } from '../../../contexts/MainPageContext'; // Используем данные из контекста
import styles from './CarShowcase.module.css';
import { SmallCarCard } from '../../../entities/SmallCarCard';

export function CarShowcase() {
    const { mainPageData, loading, error } = useContext(MainPageContext);

    // if (loading) {
    //     return <div>Загрузка автомобилей...</div>;
    // }

    if (error) {
        return <div>{error}</div>;
    }

    const cars = mainPageData?.data?.cars || [];

    return (
        <div className={styles.container}>
            <div className={styles.carList}>
                <h1 className={styles.title}>Недавно смотрели 🧐</h1>
                <div className={styles.grid}>
                    {cars.map((car, index) => (
                        <SmallCarCard car={car} key={index} />
                    ))}
                </div>
            </div>
            <h1 className={styles.title}>Может понравиться 😍</h1>
            <div className={styles.content}>
                {cars.map((car, index) => (
                    <SmallCarCard car={car} key={index} />
                ))}
            </div>
        </div>
    );
}
