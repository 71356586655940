import { RangeSelector } from "../../../shared/ui/RangeSelector";
import { Dropdown } from "../../../shared/ui/Dropdown";
import { Selector } from "../../../shared/ui/Selector";
import { useEffect, useState } from "react";
import { MultiSelect } from "../../../shared/ui/MultiSelect";
import { RangeInput } from "../../../shared/ui/RangeInput";

import styles from './CarFilters.module.css'
import { Input } from "../../../shared/ui/input/ui/Input";
import { ListOfTypes } from "../../../shared/ui/ListOfTypes/ui/ListOfTypes";
import { Checkbox } from "../../../shared/ui/Checkbox/ui/Checkbox";
import { Button } from "../../../shared/ui/button/ui/Button";
import { OrangeArrowDown } from "../../../shared/ui/Icons/OrangeArrowDown/ui/OrangeArrowDown";
import { getCarsNames } from "../lib/request";
import { SearchButton } from '../../../shared/ui/SearchButton/ui/SearchButton'; // Импортируем новый компонент
import searchIcon from '../../../shared/assets/search_glass.svg'; // 
export function CarFilters() {

    const [selectedBrand, setSelectedBrand] = useState('');

    const [selectedCarBody, setSelectedCarBody] = useState()
    const [selectedTransmission, setSelectedTransmission] = useState()
    const [selectedRadius, setSelectedRadius] = useState('10')
    const [selectedModel, setSelectedModel] = useState('')
    const [selectGeneration, setSelectGeneration] = useState([''])
    const [selectEngines, setSelectEngines ] = useState()
    const [selectedLastAdd, setSelectedLastAdd] = useState('Сегодня')
    const [selectedTypeOfSeller, setSelectedTypeOfSeller] = useState('Частник')
    const [selectedAmountOfOwners, setSelectedAmountOfOwners] = useState('До 2')

    
    const [selectDrive, setSelectDrive] = useState()
    const [startYear, setStartYear] = useState('');
    const [selectedType, setSelectedType] = useState('Все')
    const [endYear, setEndYear] = useState('');
    const years = Array.from({ length: 100 }, (_, i) => 2024 - i);

    const [selectedRegions, setSelectedRegions] = useState(["🇩🇪 Германия", "🇫🇷 Франция"]);
    const [selectedCities, setSelectedCities] = useState(["Берлин", "Гамбург"]);

    const [brands, setBrands] = useState({})
    const carBodies = ['Hatchback', "Micro compact car", "Station wagon", "Landau"]
    const transmissions = ['Manual', 'Auto'] 

    const cities = ["Берлин", "Гамбург", "Кёльн", "Париж", "Варшава"]

      const regions = ["🇩🇪 Германия", "🇫🇷 Франция", "🇵🇱 Польша"]
      
      const engines = ["бензиновый", "дизельный", "газовый", "электрический"]
      
      const drive = ["Задний привод", "Передний привод", "Полный привод"]
      
      const radius = ["10", "20", "30"]

    const types = ['Все', 'Новые', 'С пробегом']
    
    useEffect(() => {
      async function getData() {
        setBrands(await getCarsNames())
      }
      getData()
    }, [])

      const handleRegionChange = selectedRegions => {
        console.log('Selected regions:', selectedRegions);
      };
    
      const handleCityChange = selectedCities => {
        console.log('Selected cities:', selectedCities);
      };


    // const handleSelectBrand = (index, brand) => {
    //     const newSelectedBrands = [...selectedBrands];
    //     newSelectedBrands[index] = brand;
    //     setSelectedBrands(newSelectedBrands);
    //   };
    
    //   const handleAddBrandSelector = () => {
    //     setSelectedBrands([...selectedBrands, '']);
    //   };

    

        return (
          <div className={styles.layout}>

            <div className={styles.container}>
              <div className={styles.row} style={{marginBottom: '20px'}}>
                <div className={styles.rowElement}>
                  <ListOfTypes values={types} selected={selectedType} onSelect={setSelectedType}/>
                </div>
                <div className={styles.rowElementGap}>
                  <Checkbox label={'С НДС'} id='nds'/>
                  <Checkbox label={'Ниже рынка'} id='under'/>
                  <Checkbox label={'Без аварий'} id='crash'/>
                </div>
                
                <div className={styles.rowElementCarSelection}>
                <SearchButton icon={searchIcon} label="Подбор авто" redirectPath="/chat" /> {/* Используем новый компонент */}
                </div>
              </div>
              
              <div className={styles.row}>
                  
                  <div className={styles.rowElement} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Selector
                        label={'Марка'}
                        values={Object.keys(brands)}
                        value={selectedBrand}
                        width={'90%'}
                        onValue={ (car) => {setSelectedBrand(car);setSelectedModel('')}}
                        />
                        {/* <div onClick={() => {
                          setSelectGeneration((prev) => [...prev, ""])
                          setSelectModel((prev) => [...prev, ""])
                          setSelectedBrands((prev) => [...prev, "BMW"])}} style={{width: '40px', height: '40px', background: '#F0EEEE', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4rem' }}>
                          <p style={{color: ''}}>+</p>
                        </div> */}
                      </div>
                      
                  </div>

                  <div className={styles.rowElement} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                          <Selector 
                          label={'Модель'}
                          values={brands[selectedBrand] || []}
                          value={selectedModel}
                          width={'100%'}
                          onValue={ (car) => setSelectedModel(car)}
                          />

                        </div>

                  </div>

                 

                  <div className={styles.rowElementGap}  style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    {selectGeneration.map((gen, index) => (
                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', gap: '10px', alignItems: 'center'}}>
                          <Selector 
                          key={index}
                          label={'Поколение'}
                          values={['1', '2', '3', '4']}
                          value={gen}
                          width={'100%'}
                          onValue={ (car) => setSelectGeneration((prev) => {
                            let a = prev
                            a[index] = car
                            return a
                          })}
                          />
                          <Input placeholder={'Прим GTI'} />
                        </div>
                      ))}


          
                    </div>

              </div>

              <div className={styles.row} style={{marginTop: '40px'}}>

                  <div className={styles.rowElementGap}>
                      <Selector label={'Кузов'} values={carBodies} value={selectedCarBody} onValue={(carBody) => setSelectedCarBody(carBody)}/>
                      <Selector label={'Коробка'} values={["Автомат", "Механика"]} selectedBrand={selectedTransmission} onValue={(transmissions) => setSelectedTransmission(transmissions)}/>
                  </div>

                  <div className={styles.rowElementGap}>
                      <Selector label={'Двигатель'} values={engines} value={selectEngines} onValue={setSelectEngines}/>
                      <Selector label={'Привод'} values={drive} value={selectDrive} onValue={setSelectDrive}/>
                  </div>

                  <div className={styles.rowElement}>
                      <RangeInput placeholders={['Объем от, л', 'до']} />
                      
                    </div>

              </div>


              <div className={styles.row} style={{marginBottom: '40px', marginTop: '10px'}}>

                <div className={styles.rowElementRangeSelector}>

                    <Selector
                        label="Год от"
                        values={years}
                        onValue={(e) => setStartYear(e.target.value)}
                        type={'left'}
                    />
                    <Selector
                        label="до"
                        values={years}
                        value={endYear}
                        onValue={(e) => setEndYear(e.target.value)}
                        type={'right'}
                    />
                  </div>
                  <div className={styles.rowElement}>
                      <RangeInput placeholders={['Пробег от, км', "до"]} />
                  </div>

                  <div className={styles.rowElement}>
                    <RangeInput placeholders={['Мощность', 'до']} />
                    </div>

                
              </div>

              <div className={styles.row}>
                <div className={styles.rowElement}>
                  <MultiSelect  
                  values={regions}
                  label="Выберите регионы"
                  selected={selectedRegions}
                  onSelected={setSelectedRegions}

                  />  
                </div>
             

                <div className={styles.rowElementGap}>
                    <Selector withFloatingLabel={true} values={radius} onValue={setSelectedRadius} value={selectedRadius} label={'Радиус, км'}/>
                    <Selector withFloatingLabel={true} values={['Вчера', 'Неделю назад']} onValue={setSelectedLastAdd} value={selectedLastAdd} label={'Последнее добавление'}/>
                </div>

                <div className={styles.rowElement}>
                    <RangeInput placeholders={['Цена от', 'до']} />
                    </div>


              </div>


              <div className={styles.lastRow} style={{marginTop: '10px'}}>
                  <div className={styles.rowElement}>
                        <MultiSelect 
                      values={cities}
                      label="Выберите город"
                      onChange={handleRegionChange}
                      selected={selectedCities}
                      onSelected={setSelectedCities}
                    />

                  </div>

                  <div className={styles.rowElementGap}>
                      <Selector withFloatingLabel={true} values={radius} value={selectedTypeOfSeller} onValue={setSelectedTypeOfSeller} label={'Тип продавца'}/>
                      <Selector withFloatingLabel={true} values={['до 2', 'до 3', 'до 4', 'до 5']} value={selectedAmountOfOwners} onValue={setSelectedAmountOfOwners} label={'Кол-во владельцев'}/>
                      
                  </div>

              </div>

              <div className={styles.rowEnd}>
                <div className={styles.rowElementButtons}>
                  <div className={styles.allParametrs}>Все параметры <OrangeArrowDown/></div>
                  <div className={styles.delete}>Cбросить <p style={{fontSize: '22px', marginTop: '3px'}}>&times;</p></div>
                </div>

                <div className={styles.rowElement}>
                  <Selector label={'Сохраненные фильтры'} values={['1', '2', '3']}/>
                </div>
                <div className={styles.rowElement}><Button>Показать 24 567 предложений</Button></div>
              </div>

            </div>
          </div>
        )
    }