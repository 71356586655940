import { Answer } from '../../../entities/Answer/ui/Answer'
import { Message } from '../../../entities/Message'
import { Input } from '../../../shared/ui/input'
import { Button } from '../../../shared/ui/button'
import styles from './Chat.module.css'

export function Chat() {
    return (
        <div className={styles.container}>
            <Message text={'bebebbfe'}/>
            <Answer text={'ebbebebe'}/>
            <div className={styles.containerInput}>
                <Input width='70%'/>
                <Button width='30%'>Отправить</Button>
            </div>
        </div>
    )
}