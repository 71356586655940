import { CarsListHeader } from "../../../shared/ui/CarsListHeader/ui/CarsListHeader";
import styles from './CarsList.module.css'
import { carPicture } from "../../../shared/ui/pictures";
import { Sedan } from "../../../shared/ui/Sedan/ui/Sedan";
import { CarRow } from "../../../entities/CarRow";



export function CarsList({cars}) {
    return (
        <div className={styles.layout}>
        <CarsListHeader/>
            <div className={styles.container}>
                {cars && cars.map((car) => <CarRow car={car}/>)}

            </div>
        </div>
    )
}